import React from 'react';
import { DateTimeField, Form, FormValidationRules, InputField, SelectField, useForm } from '@kit/components/Form';
import { ModalBody, ModalFooter } from '@common/PromiseModal';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { useAllCompaniesUsers } from '@hooks/useCompanyUsers';
import { getFullName } from '@utils/utils';
import DropDownItem from '@common/Selector/UserSelector/DropDownItem';
import { MainUser } from '@generated/types/graphql';
import { useTaskBulk } from '@hooks/useTaskBulk';
import { BulkEditType, EDIT_TYPE_OPTIONS, SHIFT_DIRECTION_OPTIONS } from './constants';

interface Props {
  workOrderIds: number[];
  onClose: () => void;
}

type FormValues = {
  editType: { id: BulkEditType; name: string };
  direction: { id: number; name: string };
  days: number;
  startDate: Date;
  endDate: Date;
  assignee: MainUser;
  collaborators: MainUser[];
};

const RULES: FormValidationRules<FormValues> = {
  editType: {
    isRequired: true
  },
  direction: {
    isRequired: true
  },
  days: {
    isRequired: true,
    min: 1,
    max: 90
  },
  startDate: {
    isRequired: true
  },
  endDate: {
    isRequired: true
  },
  assignee: {
    isRequired: true
  },
  collaborators: {
    isRequired: true
  }
};

export const BulkEdit = ({ workOrderIds, onClose }: Props) => {
  const { data: companyUsers = [] } = useAllCompaniesUsers();

  const {
    update: { mutateAsync: bulkUpdate }
  } = useTaskBulk();

  const { form, handleSubmit } = useForm<FormValues>({
    defaultValues: {
      editType: EDIT_TYPE_OPTIONS[0],
      direction: SHIFT_DIRECTION_OPTIONS[0]
    },
    onSubmit: async (values) => {
      const { editType, direction, days, startDate, endDate, assignee, collaborators } = values;

      const payload = {
        assignees: [],
        action: editType.id === BulkEditType.SHIFT ? 'SHIFT' : 'UPDATE_PROPERTIES'
      };

      switch (editType.id) {
        case BulkEditType.SHIFT:
          payload.value = days * direction.id;
          break;

        case BulkEditType.REPLACE_ASSIGNEE:
          payload.assigneeId = assignee.id;
          break;

        case BulkEditType.REPLACE_COLLABORATORS:
          payload.assignees = collaborators.map(({ id }) => id);
          payload.replaceOldValue = true;
          break;

        case BulkEditType.ADD_COLLABORATORS:
          payload.assignees = collaborators.map(({ id }) => id);
          break;

        case BulkEditType.START_DATE:
          payload.startDate = startDate;
          payload.startDateAllDay = true;
          break;

        case BulkEditType.END_DATE:
          payload.endDate = endDate;
          payload.endDateAllDay = true;
          break;

        case BulkEditType.ARCHIVE:
          payload.isArchived = true;
          break;

        default:
          break;
      }

      await bulkUpdate({
        ids: workOrderIds,
        ...payload
      });

      onClose();
    }
  });

  const {
    watch,
    control,
    formState: { isSubmitting }
  } = form;

  const editType = watch('editType')?.id;

  return (
    <Form rules={RULES} onSubmit={handleSubmit}>
      <ModalBody width="600px">
        <SelectField
          control={control}
          label="Bulk edit type"
          name="editType"
          options={EDIT_TYPE_OPTIONS}
          getOptionLabel={(option) => option.name}
          isClearable={false}
        />

        {editType === BulkEditType.SHIFT && (
          <>
            <SelectField
              control={control}
              label="Shift direction"
              name="direction"
              options={SHIFT_DIRECTION_OPTIONS}
              getOptionLabel={(option) => option.name}
              isClearable={false}
              clearOnUnmount={false}
            />

            <InputField control={control} label="Days" name="days" type="number" />
          </>
        )}

        {editType === BulkEditType.START_DATE && (
          <DateTimeField control={control} label="Start date" name="startDate" isOnlyDate />
        )}

        {editType === BulkEditType.END_DATE && (
          <DateTimeField control={control} label="End date" name="endDate" isOnlyDate />
        )}

        {editType === BulkEditType.REPLACE_ASSIGNEE && (
          <SelectField
            control={control}
            label="Assignee"
            name="assignee"
            options={companyUsers}
            getOptionLabel={getFullName}
            noOptionsText="User not found"
            renderOption={(option: any) => (
              <DropDownItem id={option.id} avatarUrl={option.avatarUrl} name={getFullName(option)} />
            )}
          />
        )}

        {[BulkEditType.ADD_COLLABORATORS, BulkEditType.REPLACE_COLLABORATORS].includes(editType) && (
          <SelectField
            control={control}
            label="Collaborators"
            name="collaborators"
            options={companyUsers}
            getOptionLabel={getFullName}
            noOptionsText="User not found"
            isMulti
            renderOption={(option: any) => (
              <DropDownItem id={option.id} avatarUrl={option.avatarUrl} name={getFullName(option)} />
            )}
          />
        )}
      </ModalBody>
      <ModalFooter>
        <Button onClick={onClose} variant={ButtonVariant.Secondary}>
          Cancel
        </Button>
        <Button disabled={isSubmitting} variant={ButtonVariant.Primary} type="submit">
          Apply
        </Button>
      </ModalFooter>
    </Form>
  );
};
