import { useModal } from '@common/PromiseModal';
import { useActiveRecordsCountByWorkflow } from '@hooks/useRecords';
import React, { useCallback } from 'react';
import { ConfirmAddResult, ConfirmAdd } from './ConfirmAdd';

export const useConfirmAddWO = (blueprintId: number) => {
  const { openModal } = useModal();

  const { refetch } = useActiveRecordsCountByWorkflow({ workflowId: blueprintId, isEnabled: false });

  return useCallback(
    async ({
      woTemplate,
      stage
    }: {
      woTemplate: { id: number; title: string; isField: boolean };
      stage: { name: string };
    }) => {
      const { data: existingProjects } = await refetch();

      if (!existingProjects) {
        return ConfirmAddResult.Create;
      }

      const result = await openModal<ConfirmAddResult | void>(
        ({ onClose }) => <ConfirmAdd workOrderTemplate={woTemplate} stage={stage} onClose={onClose} />,
        { title: 'Confirm Workflow changes' }
      );

      return result;
    },
    [openModal, refetch]
  );
};
