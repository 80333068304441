import { ModalBody, ModalFooter } from '@common/PromiseModal';
import { WorkOrderTypeIcon } from '@common/WorkOrderTypeIcon';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { RadioGroup } from '@kit/ui/RadioGroup';
import React, { useCallback, useState } from 'react';
import { Caption, Description } from './styled';

export enum ConfirmAddResult {
  Create = 'create',
  CreateCompleted = 'createCompleted',
  CreateCancelled = 'createCancelled'
}

interface Props {
  workOrderTemplate: { id: number; title: string; isField: boolean };
  stage: { name: string };
  onClose: (result: ConfirmAddResult | void) => void;
}

const OPTIONS = [
  { value: ConfirmAddResult.Create, label: 'Create Work Order(s)' },
  { value: ConfirmAddResult.CreateCompleted, label: 'Create Work Order(s) & update their Status to COMLETED' },
  { value: ConfirmAddResult.CreateCancelled, label: 'Create Work Order(s) & update their Status to CANCELLED' }
];

export const ConfirmAdd = ({ workOrderTemplate, stage, onClose }: Props) => {
  const [selectedOption, setSelectedOption] = useState<ConfirmAddResult>(ConfirmAddResult.Create);
  const handleCancel = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleApply = useCallback(() => {
    onClose(selectedOption);
  }, [onClose, selectedOption]);

  return (
    <>
      <ModalBody>
        <Description>
          You are about to add{' '}
          <b>
            <WorkOrderTypeIcon isField={workOrderTemplate.isField} /> {workOrderTemplate.title}
          </b>{' '}
          to this Workflow.
          <br />
          How do you want to proceed with Projects that are currently in {stage.name}?
          <br />
          <br />
        </Description>
        <RadioGroup
          name="approach"
          options={OPTIONS}
          value={selectedOption}
          onChange={(e) => setSelectedOption(e.target.value)}
          renderOptionLabel={(option) => (
            <div>
              {option.label}
              {option.value === ConfirmAddResult.Create && (
                <Caption>These Work Orders will block Projects advancement for the Stages they were added in.</Caption>
              )}
            </div>
          )}
        />
      </ModalBody>
      <ModalFooter>
        <Button onClick={handleCancel} variant={ButtonVariant.Secondary}>
          Cancel
        </Button>
        <Button onClick={handleApply} variant={ButtonVariant.Primary}>
          Apply
        </Button>
      </ModalFooter>
    </>
  );
};
